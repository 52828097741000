:root
{
	--main-frame-border: 1px solid #CECECE;
}

body
{
	margin: 0;
	font-family: Arial, sans-serif;
}

.css_grid_container
{
	user-select: none;
	display: grid;
	grid-template-areas:
		"sidebar header header"
		"sidebar canvas canvas"
		"sidebar footer footer";
	grid-template-columns: 65px 1fr;
	grid-template-rows: auto 1fr auto;
	height: 100vh;
}

.header,
.footer
{
	border-top: var(--main-frame-border);
	color: #fff;
	padding: 10px;
	align-items: center;
	color: #000;
}

.header
{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 20px;
	grid-area: header;

	border-bottom: var(--main-frame-border);
}

.footer
{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 20px;

	grid-area: footer;
}

.toolbar-left
{
	text-align: left;
}

.toolbar-middle
{
	text-align: center;
}

.toolbar-right
{
	text-align: right;
}

.canvas
{
	grid-area: canvas;
	background: #e1e1e1;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;

}

.sidebar
{
	background-color: #fff;
	border-right: var(--main-frame-border);
	padding: 10px;
	grid-area: sidebar;
	border-left: 1px solid #ccc;


	display: flex;
	flex-direction: column;
	justify-content: center;
	// height: 100%;

}

#toolbar
{
	border: var(--main-frame-border);
	text-align: center;
	display: none;
	background: #fff;
	padding: 12px;
	border-radius: 50px;
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	display: none;
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

// #tooltip
// {
// 	display: none;
// 	position: absolute;
// 	background-color: white;
// 	border: 1px solid black;
// 	padding: 5px;
// 	right: 0;
// }